<template>
  <div class="w-full flex px-2 py-1 bg-white rounded items-center">

    <div class="flex flex-col text-primary text-xl font-bold">
      <div class="font-bold text-lg">{{ visitor.name }}</div>
      <div class="flex items-center">
        <font-awesome-icon icon="ruler-horizontal" class="text-xl" />
        <div class="ml-2">{{ visitor.height.toFixed(2) }}m</div>
        <!-- <font-awesome-icon icon="user-friends" class="text-xl ml-4" /> -->
      </div>
    </div>

    <div class="ml-auto">
      <img class="w-12" :src="visitor.image" alt="">
    </div>

  </div>
</template>

<script>
export default {
  props: ['visitor']
}
</script>
