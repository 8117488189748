<template>
  <div class="h-screen flex flex-col">
    <navbar />
      <!-- <div class="rounded overflow-hidden shadow bg-cover">
        <img :src="attraction && attraction.image" class="w-40">
      </div>
      <div class="py-4 text-white ml-4 flex flex-col justify-center">
        <div class="text-lg font-bold">{{ attraction && attraction.name }}</div>
        <div>
          <font-awesome-icon icon="user-friends" class="mr-2" /> {{ total }}
        </div>

      </div> -->

    <div class="flex-1 min-h-0 flex">
      <div class="w-1/5 flex flex-col bg-white shadow-xl pt-6 pb-2">
        <div class="flex px-4">
          <div>
            <img :src="attraction && attraction.image" class="w-24">
          </div>
          <div class="px-2 flex flex-col justify-between">
            <div class="text-xl font-medium">{{ attraction && attraction.name }}</div>
            <div class="flex text-primary">
              <font-awesome-icon icon="user-friends" class="mr-2" /> {{ total }}
            </div>
          </div>
        </div>

        <div class="bg-primary text-white flex mt-4 px-5 py-4 items-center">
          <font-awesome-icon icon="clock" class="mr-2 text-3xl" />
          <div class="font-medium text-xl ">Wachtrijen</div>
        </div>

        <div class="border-b border-primary text-primary flex px-5 py-5 items-center">
          <font-awesome-icon icon="traffic-light" class="mr-2 text-3xl" />
          <div class="font-medium text-xl ">Bediening</div>
        </div>

        <div class="border-b border-primary text-primary flex px-5 py-5 items-center">
          <font-awesome-icon icon="chart-line" class="mr-2 text-3xl" />
          <div class="font-medium text-xl ">Statistieken</div>
        </div>


        <div class="mt-auto text-primary text-center font-light text-sm">Virtua theme park V2.1.4</div>
      </div>

      <div class="w-4/5 flex flex-col">
        <div class="w-full">
          <div class="flex border-b border-primary text-center">
            <div class="w-1/5 flex items-center justify-center">
              <div class="text-primary text-3xl font-bold">Tijdslot</div>
            </div>
            <div class="w-2/5 border-l border-primary">
              <div class="text-primary text-3xl font-bold">Virtuele wachtrij</div>
            </div>
            <div class="w-2/5">
              <div class="text-primary text-3xl font-bold">Fysieke wachtrij</div>
            </div>
          </div>
        </div>

        <div class="w-full overflow-y-auto">
          <div
            class="flex border-b border-dashed border-gray-500"
            v-for="(slot, index) in sortedSlots"
            :key="slot.date"
            v-bind:class="{'bg-primary bg-opacity-25': index == 1}"
          >
            <div class="w-1/5 flex items-start justify-end relative">
              <div class="flex items-center">
                <div class="text-primary text-2xl font-bold mr-4">{{ slot.date }}</div>
                <div class="absolute right-0 -mr-2 w-4 h-4 rounded-full bg-primary"></div>
              </div>
            </div>
            <div class="w-2/5 border-l border-primary pl-8">
              <transition-group name="list-complete" tag="div" class="w-full">
                <div v-for="queue of slot.queue" :key="queue.id" class="p-2 list-complete-item w-full">
                  <queue-slot :queue="queue" />
                </div>
              </transition-group>
            </div>
            <div class="w-2/5 bg-gray-500 bg-opacity-25">
              <transition-group name="list-complete" tag="div" class="w-full">
                <div v-for="queue of slot.history" :key="queue.id" class="p-2 list-complete-item w-full">
                  <queue-slot :queue="queue" :disabled="true" />
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: scale(0);
}
.list-complete-leave-active {
  position: absolute;
}
</style>

<script>
import store from '../../store'
import Navbar from '@/components/Navbar'
import QueueSlot from '@/components/QueueSlot'

import moment from 'moment'

export default {
  components: {
    Navbar,
    // eslint-disable-next-line vue/no-unused-components
    QueueSlot
  },
  mounted () {

  },
  computed: {
    attractionId () {
      return this.$route.params.attraction
    },
    total () {
      return store.queues.reduce((carry, item) => {
        if (item.attraction.id != this.attractionId) return carry
        else return carry + item.visitors.length
      }, 0)
    },
    sortedSlots () {
      return Object.values(this.slots).sort((a, b) => {
        return  a.time - b.time
      })
    },
    slots () {
      const slots = {}

      for (const queue of store.queues) {
        if (queue.attraction.id != this.attractionId)
          continue

        const [slot, time] = this.getTimeslot(queue)

        if (!(slot in slots)) {
          slots[slot] = {
            time,
            date: slot,
            queue: [],
            history: []
          }
        }

        slots[slot].queue.push(queue)
      }

      for (const queue of store.queues_history) {
        if (queue.attraction.id != this.attractionId)
          continue

        const [slot, time] = this.getTimeslot(queue)

        if (!(slot in slots)) {
          slots[slot] = {
            time,
            date: slot,
            queue: [],
            history: []
          }
        }

        slots[slot].history.push(queue)
      }

      return slots
    },
    attraction () {
      return store.attractions.find((attraction) => {
        return attraction.id == this.attractionId
      })
    }
  },
  methods: {
    getTimeslot (queue) {
      const seconds = queue.available_at - queue.available_at % (60 * 15)
      const time =  moment(seconds * 1000)

      return [time.format('HH:mm'), time.unix()]
    }
  }
}
</script>
