<template>
    <div class="w-full rounded p-2 bg-primary flex" v-bind:class="{'opacity-75': disabled}">
      <div class="pr-2 text-white font-medium">{{ moment(queue.available_at*1000).format('HH:mm') }}</div>
      <div class="w-full">
        <div
          v-for="(visitor, index) in queue.visitors.slice().sort((a, b) => b.height - a.height)"
          :key="visitor.id"
          v-bind:class="{'ml-12': visitor.height < 1.70, ' mt-2': index !== 0}"
        >
          <queue-visitor :visitor="visitor" />
        </div>
      </div>
    </div>
</template>

<script>
import QueueVisitor from '@/components/QueueVisitor'

export default {
  components: {
    QueueVisitor
  },
  props: ['queue', 'disabled'],
  mounted() {
    console.log(this.queue)
  }
}
</script>
